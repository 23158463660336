import PropTypes from 'prop-types';
import getOr from 'lodash/fp/getOr';
import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import {
  breakpoints,
  colors,
  unitConverter as uc,
  pageDimensions,
} from '../../../styles/base';
import EventTile from '../../molecules/EventTile/EventTile';
import Layout from '../../organisms/Layout/Layout';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import SlantBanner from '../../organisms/SlantBanner/SlantBanner';
import TileContainer from '../../molecules/TileContainer/TileContainer';
import client from '../../../utils/sanityClient';
import useSanityFetchDocuments from '../../../hooks/useSanityFetchDocuments';
import useLocalize from '../../../hooks/useLocalize';

const eventsQueryFunction = locale => `//groq  
*[_type=="event" && !(_id in path("drafts.*")) && enabledLocale=="${locale}"] {
    _id,
    location,
    eventDate,
    eventType,
    enabledLocale,
    title,
    url,
    thumbnail {
      _type,
      asset-> {
        url
      },
    },
  } | order(eventDate asc)
`;

const EventsPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  const eventsQuery = useMemo(() => eventsQueryFunction(locale), [locale]);
  const { data: queryData } = useSanityFetchDocuments(client, eventsQuery);
  const data = useLocalize(rawData, locale);
  const eventsPageProps = {
    id: data.eventsPage._id,
    metadata: { ...data.eventsPage._rawRoute, href },
    eventTilesData: queryData,
  };

  const mainContainerCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: ${pageDimensions.desktopWidth};
    margin: auto ${uc('60px')};
    padding-top: ${uc('60px')};
    background-color: ${colors.white};

    @media (${breakpoints.mobile}) {
      padding-top: ${uc('40px')};
    }
  `;

  const listContainerCss = css`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
  `;

  return (
    <Layout
      menuColor="white"
      locale={locale}
      sanityId={eventsPageProps.id}
      {...eventsPageProps.metadata}
    >
      <SlantBanner {...getOr([], 'eventsPage._rawSlantBanner', data)} />
      <PageWrapper>
        <div css={mainContainerCss}>
          <div css={listContainerCss}>
            {eventsPageProps.eventTilesData && (
              <>
                {eventsPageProps.eventTilesData.map(event => {
                  if (!event.url) return null;
                  return (
                    <TileContainer key={event._id} columns={4}>
                      <EventTile
                        link={event.url}
                        location={event.location}
                        eventDate={event.eventDate}
                        type={event.eventType}
                        title={event.title}
                        thumbnail={event.thumbnail.asset.url}
                      />
                    </TileContainer>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </PageWrapper>
    </Layout>
  );
};

EventsPage.propTypes = {
  data: PropTypes.shape({ eventsPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

EventsPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default EventsPage;

export const query = graphql`
  query($slug: String) {
    eventsPage: sanityEventsPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSlantBanner(resolveReferences: { maxDepth: 10 })
    }
  }
`;
