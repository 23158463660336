import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { format } from 'date-fns';
import BaseTile from '../BaseTile/BaseTile';
import { colors, fontSizes, unitConverter as uc } from '../../../styles/base';
import Link from '../../atoms/Link/Link';
import sanityImage from '../../../utils/sanityImage';
import Text from '../../atoms/Text/Text';

const descriptionCss = css`
  margin: ${uc('10px 0 18px')};
  font-size: ${fontSizes.fourteen};
`;

const customCss = css`
  .container {
    width: auto;
    height: auto;
    min-height: ${uc('375px')};
  }

  .title {
    font-size: ${fontSizes.sixteen};
  }

  .subtitle {
    margin: 0;
    color: ${colors.mediumGray};
    font-size: ${fontSizes.twelve};
  }

  .image-wrapper {
    height: auto;
    padding: inherit;
  }

  .image {
    width: auto;
    max-height: unset;
  }
`;

const EventTile = ({ eventDate, link, location, thumbnail, title, type }) => {
  // convert iso string date to MM/dd/yyyy
  const date = format(new Date(eventDate), 'MM/dd/yyyy');

  return (
    <Link to={link} target="_blank">
      <BaseTile
        customCss={customCss}
        title={title}
        subtitle={date}
        imageUrl={sanityImage(thumbnail)
          .auto('format')
          .fit('max')
          .width(500)
          .url()}
      >
        <Text className="subtitle">{location}</Text>
        <Text css={descriptionCss}>{type.toUpperCase()}</Text>
      </BaseTile>
    </Link>
  );
};

EventTile.propTypes = {
  eventDate: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

EventTile.defaultProps = {
  type: 'event',
};

export default EventTile;
