import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core'; // eslint-disable-line

import { breakpoints, unitConverter as uc } from '../../../styles/base';

/**
 * Responsive tile container
 */
const TileContainer = ({ children, className, columns }) => {
  const columnSpacing = columns > 1 ? '2%' : 0;
  const tileContainerCss = css`
    position: relative;
    width: calc(100% / ${columns} - ${columnSpacing});
    max-width: ${uc('430px')};

    @media (${breakpoints.largeTablet}) {
      width: 48%;
      max-width: 98%;
    }

    @media (${breakpoints.mobile}) {
      width: 100%;
      margin-bottom: ${uc('40px')};
    }
  `;

  return (
    <div css={tileContainerCss} className={className}>
      {children}
    </div>
  );
};

TileContainer.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  columns: PropTypes.number,
};

TileContainer.defaultProps = {
  children: null,
  className: '',
  columns: 3,
};

export default TileContainer;
