import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Card from '../../atoms/Card/Card';
import Image from '../../atoms/Image/Image';
import { Heading3 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';

const defaultCss = css`
  .title {
    margin: ${uc('10px 0 10px')};
    color: ${colors.primary};
    font-weight: ${fontWeights.semiBold};
    line-height: ${lineHeights.copy};
  }

  .container {
    width: ${uc('284px')};
    height: ${uc('300px')};
    margin: ${uc('0px 14px 20px 0px')};
    overflow: hidden;
    border-radius: ${uc('30px')};

    @media (${breakpoints.mobile}) {
      width: 100%;
    }
  }

  .content-wrapper {
    width: 100%;
    height: 100%;
    padding: ${uc('20px')};
    background-color: ${colors.white};
  }

  .subtitle {
    color: ${colors.accent};
    font-weight: ${fontWeights.bold};
    text-transform: uppercase;
  }

  .image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${uc('120px')};
    padding: ${uc('20px')};
    background-color: ${colors.white};
    border-bottom: ${uc('1px')} solid ${colors.lightGray.one};
  }

  .image {
    flex-shrink: 0;
    width: auto;
    max-height: ${uc('120px')};
  }
`;

const BaseTile = ({ children, customCss, imageUrl, subtitle, title }) => {
  const componentCss = customCss
    ? css`
        ${defaultCss}

        ${customCss}
      `
    : defaultCss;
  return (
    <div css={componentCss}>
      <Card className="container">
        {imageUrl && (
          <div className="image-wrapper">
            <Image className="image" src={imageUrl} />
          </div>
        )}
        <div className="content-wrapper">
          {title && <Heading3 className="title">{title}</Heading3>}
          {subtitle && <Text className="subtitle">{subtitle}</Text>}
          {children}
        </div>
      </Card>
    </div>
  );
};

BaseTile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element).isRequired,
  ]),
  customCss: PropTypes.instanceOf(Object),
  imageUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

BaseTile.defaultProps = {
  customCss: null,
  children: null,
};

export default BaseTile;
